.notLoggedInModal__title {
  font-size: 18px;
  font-weight: 500;
}

.notLoggedInModal__subtitle {
  font-size: 14px;
  font-weight: 300;
  margin-top: 0px;
  padding-top: 0px;
}

.notLoggedInModal__loginBtn {
  margin-bottom: 20px;
}
.notLoggedInModal__loginBtn,
.notLoggedInModal__signupBtn {
  background-color: #009ef7;
  border-radius: 6px;
  border: none;
  padding: 10px 20px;
  color: #fff;
  font-weight: 500;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.notLoggedInModal__closeBtn {
  position: absolute;
  margin-left: 300px;
  margin-top: -20px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
