.News {
  // max-width: 601px;
  height: 100%;
  display: flex;
  flex-flow: row;
  width: 100%;
  max-width: 1135px;
  justify-content: space-between;
  // background-color: #fff;

  @media only screen and (max-width: 768px) {
    flex-flow: column;
  }
}

.NewsContainer {
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  height: 100%;
  margin-left: 183px;
  margin-right: 182px;

  @media only screen and (max-width: 1200px) {
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 100px;
  }
}

.news__section__title {
  font-size: 18px;
  color: #100;
  font-weight: 500;
}

.news__topStory {
  display: flex;
  flex-flow: row;

  margin-top: 22px !important;

  @media only screen and (max-width: 768px) {
    display: block;
    // flex-flow: row;
  }
}

.news__topStory__card--newRow {
  flex-flow: row;
}

.news__topStory__card {
  height: 452px;
  width: 100%;

  background-color: #fff;
  padding: 20px;
  padding-bottom: 34px;
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);
  // margin-left: 27px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  &:first-child {
    margin-left: 0px;
  }
  // overflow-y: sc;

  @media only screen and (max-width: 900px) {
    // height: 480px;
    justify-content: space-between !important;
  }

  @media only screen and (max-width: 768px) {
    margin-left: 0px;
  }
}

.news__topStory__card__img {
  width: 100%;
  height: 155px;
  border-radius: 12px;

  @media only screen and (max-width: 900px) {
    height: 230px;
  }

  @media only screen and (max-width: 500px) {
    height: 155px;
  }
}

.news__topStory__card__headliner {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 10px;
}
.news__topStory__card__headliner__sourceTxt,
.news__topStory__card__headliner__date {
  color: #6c7289;
  font-size: 12px;
}

.news__topStory__card__headliner__divider {
  color: #6c7289;
  margin-right: 10px;
  margin-left: 10px;
}

.news__topStory__card__description,
.news__headline__card__description {
  font-size: 18px;
  color: #100;
  font-weight: 500;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.news__headline__card__description {
  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
}

// headlines
.news__headlines {
  margin-top: 27px;
}

.news__leftColumn {
  width: 70%;
  margin-right: 24px;

  @media only screen and (max-width: 900px) {
    width: 100%;
    margin-right: 0px;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;

    /* For Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      display: none;
    }
    overflow-y: scroll;

    margin-right: 0px;
    height: 100%;
    // margin-bottom: 100px;
  }
}

.news__rightColumn {
  width: 30%;

  @media only screen and (max-width: 900px) {
    display: none;
  }
}

.news__rightColumn__weather {
  margin-top: 20px;
}

.news__section__titleWrap {
  display: flex;
  align-items: center;
  width: 100%;
}

.news__section__chevronRightBtn {
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  margin-left: 15px;
}

/* Add this to your existing CSS */
.control-dots {
  display: flex;
  justify-content: center;

  margin-top: 16px; /* Adjust as needed */
}

.dot {
  width: 10px;
  height: 10px;
  margin: 0 8px; /* Adjust as needed */
  border-radius: 50%;
  background-color: #ccc; /* Default dot color */
  cursor: pointer;
}

.dot.active {
  background-color: #6c7289; /* Active dot color */
}

.carousel .slide {
  text-align: left;
}

.control-dots {
}

.news__topStory--web {
  display: flex;
  width: 100%;
}

.news__topStory--web .news__topStory__card:first-child {
  margin-right: 27px;
}

.news__section__title--invisibleRightBlock {
  width: 100px;
  @media only screen and (max-width: 768px) {
    width: 50px;
  }
}

.news__headlinerWrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 15px;
}

.clickableLink {
  &:hover {
    opacity: 0.8;
    cursor: pointer;
    text-decoration: underline;
  }
}
