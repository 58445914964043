.voterAdminModals__thankYou {
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;

  padding: 73px 100px 73px 100px;
}

.voterAdminModals__thankYou__img {
  width: 76px;
  height: 76px;
}

.voterAdminModals__thankYou__label {
  font-weight: 500;
  font-size: 32px;
  color: #50cd89;
  margin-top: 18px;
  margin-bottom: 18px;
}

.voterAdminModals__thankYou__subtitle {
  font-size: 24px;
  font-weight: 500;
  color: #3f4254;
  text-align: center;
}
