.VoterAdminNavbar {
  height: 100px;
  padding-top: 30px;
  padding-bottom: 23px;
  padding-left: 45px;
  padding-right: 82px;
  background-color: #fff;

  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}
