.myPetitions__nonActiveTab {
  font-weight: 500;
  font-size: 15px;
  color: #6c7289;
  margin-left: 20px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.myPetitions__activeTab {
  font-weight: 600;
  font-size: 15px;
  color: #3699ff;

  border-bottom: 3px solid #3699ff;

  border-collapse: separate;
  border-spacing: 10px 30px;
  padding-bottom: 10px;
}
