.noNews__card {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 615px;
  width: 100%;
  background-color: #fff;
  border-radius: 12px;
  margin-top: 25px;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);
}

.noNews__card__title {
  font-size: 18px;
  margin-top: 15px;
}
