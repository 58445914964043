.VoterAdminDashboard {
  background-color: #e1edf9;
  height: 100vh;
  width: 100%;
}

.adminDash__statCard {
  background-color: #fff;
  margin-right: 33px;
  width: 100%;
  height: 164px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom: 4px solid #f1bc00;

  padding-top: 26px;
  padding-bottom: 16px;
  padding-left: 26px;
  padding-right: 26px;

  display: flex;
  flex-flow: column;
}

.adminDash__pendApproveCard {
  border-color: #f1bc00;
}

.adminDash__regVotersCard {
  border-color: #3699ff;
}

.adminDash__declineVotersCard {
  border-color: #f1416c;
}

.adminDash__successVotersCard {
  border-color: #50cd89;
}

.adminDash__content {
  padding-left: 290px;
  padding-top: 115px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-right: 17px;
}

.adminDash__statCardIcon {
  width: 32px;
  height: 32px;
}

.adminDash__statCardValue {
  font-size: 30px;
  margin-top: 13px;
  margin-bottom: 13px;
  font-weight: 500;
}

.adminDash__statCardLabel {
  font-size: 14px;
  font-weight: 500;
}
