.petitionSupporters__profileImg {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 15px;
}

.petitionSupporters__list {
  margin-top: 11px;
  width: 100%;

  max-width: 700px;

  @media only screen and (max-width: 768px) {
    margin-top: 80px;
    max-width: none;
  }
}

.petitionSupporters__profileWrap {
  display: flex;
  flex-flow: row;
}

.petitionSupporters__profileTxtWrap {
  display: flex;
  flex-flow: column;
}

.petitionSupporters__name {
  font-weight: 600;
  font-size: 16px;
  color: #3f4254;
}

.petitionSupporters__location {
  font-weight: 500;
  font-size: 13px;
  color: #6c7289;
}

.petitionSupporter__profileLetter {
  width: 42px;
  height: 42px;

  margin-right: 15px;
  font-size: 20px;
}

.petitionSupporters__profileWrap {
  margin-bottom: 18px;

  .navbar__barNameLetterWrap {
    border-radius: 50%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    margin-left: 0px;
    width: 42px;
    height: 42px;

    .petitionSupporter__profileLetter {
      margin-right: 0px;
    }
  }
}
