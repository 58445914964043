.VoterModalHeader {
  padding: 25px 20px 25px 30px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eef2f5;
}

.voterModalHeader__logo {
  height: 33.92px;
}

.voterModalHeader__title {
  font-weight: 500;
  font-size: 22px;
  color: #3f4254;
}
