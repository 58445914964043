.VoterInfoModal {
  width: 830px;
  max-height: 850px;
  display: flex;
  flex-flow: column;

  overflow: scroll;

  margin-bottom: 30px;
  // justify-content: space-between;
}

.voterInfoModal__accessCodeWrap {
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  margin-left: 30px;
  margin-right: 30px;
}

.voterInfoModal__accessCode__txtWrap {
  display: flex;
  flex-flow: row;
}

.voterInfoModal__accessCode__label {
  color: #f1416c;
  font-size: 16px;
  font-weight: 500;
}

.voterInfoModal__accessCode__date {
  color: #3f4254;
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px;
}

.voterInfoModal__accessCode__link {
  font-weight: 500;
  font-size: 16px;
  color: #3699ff;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.voterInfoModal__divider--top {
  border-top: 1px solid #eef2f5;
  margin-bottom: 25px;
}

.voterInfoModal__divider--bottom {
  border-top: 1px solid #eef2f5;
  margin-top: 25px;
}

.voterInfoModal__dividerWrap {
  margin-bottom: 100px;
}
