.NewsGuard {
  font-family: Poppins;
}

.newsguard__btn {
  margin-top: -12px;
}

.newsguard__btnTxtWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.newsguard__btnTxt {
  font-size: 9px;
  font-weight: 600;
  color: #6c7289;
  margin-left: 5px;
}

.divider {
  border-bottom: 1px solid #d0d0d0;
  margin: 0 -15px;
}

.newsGuardLabel__card {
  z-index: 99999999999999999999 !important;
}

.newsguard__btnTxt__score {
  background-color: #3355dd;
  width: 48px;
  height: 22.2px;
  padding-left: 3px;
  margin-top: -0.5px;
  // margin-left: -0.1px;
}

.newsguard__btnTxt__scoreTxt {
  color: #fff;
  font-weight: 700;
}

.shield_container_div {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  height: auto;
  margin: 0px 4px 0px 0px;
}

.shield_child_div {
  font-size: 15px;
  font-family: Sora, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background: rgb(51, 85, 221);
  border-radius: 0px 4px 4px 0px;
  color: rgb(255, 255, 255);
  font-weight: 500;
  padding: 0px 3px;
  height: 16px;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  
}