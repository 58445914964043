.ModalFooter {
  padding: 25px 20px 25px 30px;
  border-top: 1px solid #eef2f5;
  display: flex;
  justify-content: flex-end;
}

.modalFooter__cancelBtn {
}

.modalFooter__primaryBtn {
  height: 47px;
  width: fit-content;
  padding: 12px 20px;
  border-radius: 6px;
  border: none;

  color: #fff;
  background-color: #3699ff;
  font-weight: 600;
  font-size: 15px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
