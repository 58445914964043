.twoColumnList {
  display: flex;

  @media only screen and (max-width: 768px) {
    flex-flow: column;
    margin-right: 0;
  }
}

.twoColumnList__column--1,
.twoColumnList__column--2 {
  width: 100%;
}

.twoColumnList__column--2 {
  margin-left: 27px;
  @media only screen and (max-width: 768px) {
    margin-left: 0px;
  }
}
