.voterElectorList__header {
  margin-left: 25px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.voterElectorList__header__label {
  font-weight: 700;
  font-size: 20px;
  color: #7c3242;
}

.voterElectorList__header__inputsWrap {
  display: flex;
  flex-flow: row;
}

//COLUMNS
.electorListTable__columnLabel--1 {
  width: 6%;
}

.electorListTable__columnLabel--2 {
  width: 10%;
}

.electorListTable__columnLabel--3 {
  width: 10%;
}

.electorListTable__columnLabel--4 {
  width: 20%;
}

.electorListTable__columnLabel--5 {
  width: 12%;
}

.electorListTable__columnLabel--6 {
  width: 13%;
}

.electorListTable__columnLabel--7 {
  width: 12%;
}

//ROWS
.electorListTable__rowItem--1 {
  width: 7%;
}
.electorListTable__rowItem--2 {
  width: 10%;
}
.electorListTable__rowItem--3 {
  width: 10%;
}
.electorListTable__rowItem--4 {
  width: 20%;
}
.electorListTable__rowItem--5 {
  width: 13%;
}
.electorListTable__rowItem--6 {
  width: 13%;
}
.electorListTable__rowItem--7 {
  // width: fit-content;
  width: 10%;
}

.electorListTable__rowItem--8 {
  width: 5%;
  display: flex;
  justify-content: flex-end;
}

// ROW STYLING
.electorListTable__rowItem--1,
.electorListTable__rowItem--2,
.electorListTable__rowItem--3,
.electorListTable__rowItem--4,
.electorListTable__rowItem--5,
.electorListTable__rowItem--6 {
  font-size: 14px;
  font-weight: 500;
  color: #3f4254;
}

.electorListTable__rowItem--1 {
  height: fit-content;
  color: #3699ff;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

// header

.voterInfoModal__header__electorId {
  font-size: 22px;
  font-weight: 500;
  margin-right: 34px;
  color: #6c7289;
}

.voterInfoModal__header__leftWrap,
.voterInfoModal__header__rightWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.voterInfoModal__header__title {
  font-size: 22px;
  font-weight: 500;
  margin-right: 20px;
}
