.LocalNews {
  padding: 20px;
  padding-right: 15px;
  background-color: #fff;
  width: 100%;
  overflow-y: scroll;
  max-height: fit-content;
  display: flex;
  margin-top: 15px;
  border-radius: 12px;

  display: flex;
  flex-flow: column;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);
}

.LocalNews > div:last-child {
  border-bottom: 0px;
  margin-bottom: 0px;
}

.localNews__post {
  border-bottom: 1px solid #b5b5c3;
  margin-bottom: 20px;
}

.localNews__post__content {
  display: flex;
  justify-content: space-between;
}

.localNews__post__content__txt {
  font-size: 14px;
}

.localNews__post__content__img {
  width: 66px;
  height: 61px;
  border-radius: 12px;
  margin-left: 18px;
}

.news__localNews__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media only screen and (max-width: 900px) {
    margin-top: 50px;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 40px;
  }
}

.news__localNews__header--fullpageView {
  @media only screen and (max-width: 768px) {
    margin-top: 0px;
  }
}
