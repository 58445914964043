.feed__postActionBarBtn--homeFeed {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 24px;
  padding-right: 24px;
}

.feed__postActionBar {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 10px;

  padding-top: 4px;
  padding-bottom: 4px;

  @media only screen and (max-width: 768px) {
    justify-content: flex-start;
    padding-left: 0px;
  }
}

.postActionBar--topStory {
  display: flex;
  flex-flow: row;
  justify-content: flex-start !important;
}
