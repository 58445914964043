@use '../../../styles/civiktheme' as theme;

.Profile {
  padding: 50px 0px;
  min-width: 1145px;
  max-width: 1145px;
}

.ProfileEdit {
  padding: 50px 0px;
  margin-top: 30px;
}

.profile__title {
  color: #3f4254;
  margin-left: 20px;
}

.profile__subtitlebtn {
  color: theme.$civik-pimary-dark-grey;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 20px;
  border: none;
  background-color: transparent;
  display: flex;
  flex-flow: row;
}

.profile__titleBar {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}
.profile__subtitleBar {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 20px;
}

.profile__myPosts {
  display: flex;
  justify-content: center;
  margin-top: -30px;
}

.profile__about,
.profile__contact,
.profile__socials {
  background-color: #fff;
  padding: 25px;
  border-radius: 8px;
}

.profile__about {
  margin-top: 20px;

  width: 100%;
}

.profile__contact {
  width: 100%;
  margin-left: 20px;
}

.profile__socials {
  width: 100%;
  margin-left: 20px;
  margin-top: 20px;
}

.profile__contentOuterWrap {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.profile__councilDisclaimer {
  display: flex;
  flex-flow: row;

  align-items: center;

  height: 71px;
  width: 100%;
  background-color: #fff7e7;
  border: 1px dashed #ffa800;

  border-radius: 6px;
  margin-bottom: 20px;

  .disclaimerInnterContainer {
    display: flex;
    flex-flow: row;
  }
}

.profile__disclaimerPrimary {
  background-color: theme.$civik-input-light-blue;
  border-color: theme.$civik-primary-blue;
}

.profile__warningIcon {
  width: 35.36px;
  height: 35.36px;
  margin-left: 20px;
}

.profile__councilDisclaimerTxtWrap {
  margin-left: 15px;
  display: flex;
  flex-flow: column;
}

.profile__councilDisclaimerTxtTitle {
  color: #3f4254;
  font-weight: 600;
}

.profile__councilDisclaimerTxtSubtitle {
  color: #7e8299;
  font-weight: 500;
}

.profile__councilDisclaimerTxtPrimary {
  color: theme.$civik-primary-blue;
}

.profile__locationDisclaimerTxtSubtitle {
  font-weight: 400;
  cursor: pointer;
  color: theme.$civik-primary-blue;
}

.profile__disclaimerButton {
  margin-right: 15px;
  padding: 12px 20px;

  background: rgba(255, 95, 1, 0.08);
  /* Primary Dark Orange */

  border: 1px solid #ff5f01;
  border-radius: 6px;

  color: #ff5f01;
  font-weight: 600;
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 1192px) {
  .Profile {
    min-width: 100%;
    max-width: 100%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .Profile {
    padding: 30px 0px;
    margin-top: 25px;
    min-width: 100%;
    max-width: 100%;
  }

  .ProfileEdit {
    padding: 30px 0px;
    margin-top: 20px;
  }

  .profile__title {
    margin-bottom: 0px;
  }

  .profile__titleBar {
    margin-top: 20px;
  }
  .profile__subtitleBar {
    margin-top: 20px;
  }

  .profile__contentOuterWrap {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 50px;
  }

  .profile__contact {
    margin-left: 0px;
    margin-top: 10px;
  }

  .profile__socials {
    margin-left: 0px;
    margin-top: 10px;
  }

  .profile__warningIcon {
    margin-left: 10px;
  }
}
