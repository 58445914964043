// micromodal default styling

// .modal {
//   background-color: yellow;
//   padding: 30px;
//   height: 50%;
//   width: 600px;
//   position: absolute;
//   display: flex;
//   align-self: center;
// }

//
@use '../../../styles/civiktheme' as theme;

.clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
}

.civik-side-banner {
  background-image: url('../../../relative_assets/login-side-image-large.jpg');
  overflow: hidden;
  background-size: contain;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 35%;
  height: 100vh;
  position: fixed;
}

.civik-auth-page {
  background-color: #fff;
  margin-left: 35%;
  height: 100vh;
  @media only screen and (max-width: 500px) {
    height: auto;
  }
  @media only screen and (max-width: 1200px) {
    height: 90vh;
  }
}

.civik-social-login-wrapper {
  display: flex;
  flex-flow: row;
  justify-content: space-around;

  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
}

.civik-social-btn {
  width: 62px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.civik-side-banner-content-wrapper {
  display: flex;
  height: 50%;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
}

.civik-side-banner-txt-wrapper {
  border: 9px solid rgba(255, 255, 255, 0.4);
  margin: 120px 80px;
  padding: 25px;
}

.civik-side-banner-txt-svg {
  width: 260px;
}

.civik-side-banner-logo {
  width: 130px;
}

.login-form-wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.civik-primary-btn {
  color: theme.$civik-primary-blue;
  font-weight: 600;
  font-size: 14px;
  border-radius: 6px;
  border: none;
  background-color: #e1f0ff;
  width: 270px;
  height: 50px;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  margin-bottom: 16px;
}

.civik-primary-btn:hover {
  opacity: 0.8;
}

.civik-white-btn {
  color: theme.$civik-primary-blue;
  font-weight: 600;
  font-size: 14px;
  border-radius: 6px;
  border: none;
  background-color: #fff;
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 10px 20px;
}

.civik-btn-outline-primary {
  padding: 12px 20px;
  border-radius: 6px;
  background: theme.$civik-bg-light-blue;
  border: 1px solid theme.$civik-primary-blue;
  color: theme.$civik-primary-blue;
  font-weight: 600;
}

.civik-btn-outline-warning {
  padding: 12px 20px;
  border-radius: 6px;
  font-weight: 600;
  background: rgba(255, 95, 1, 0.08);
  /* Primary Dark Orange */
  border: 1px solid #ff5f01;
  color: #ff5f01;
}

.civik-btn-icon {
  width: 49.73px;
  height: 50px;
  margin-right: 25px;
}

.civik-or-separator {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-flow: row;

  span {
    font-weight: 600;
    font-size: 15px;
    color: #7e8299;
  }

  hr {
    border-color: #7e8299;
    border: 1px solid;
    width: 80%;
  }

  hr:first-child {
    margin-right: 10px;
  }

  hr:last-child {
    margin-left: 10px;
  }
}

.civik-link {
  font-weight: 600;
  font-size: 16px;
  color: theme.$civik-primary-blue;

  &:hover {
    opacity: 0.8;
    text-decoration: underline;
    cursor: pointer;
  }
}

.civik-auth-navbar {
  background-color: #fff;
  width: 100%;

  height: 70px;
  padding: 20px;
  // border-bottom: 1px solid #c6c7cc;
}

.show-mobile {
  display: none;
}

.civik-auth-navbar-logo {
  height: 100%;

  &:hover {
    cursor: pointer;
  }
}

.auth-footer {
  background-color: #fff;
  height: auto;
  width: 80%;
  display: flex;
  justify-content: space-around;
  flex-flow: row;
  padding-bottom: 50px;
}

.civik-auth-form {
  margin-top: 40px;
}

.civik-cancel-reset-pass {
  color: #7e8299;
  font-weight: 600;
  margin-top: 40px;
  font-size: 14px;
  text-align: center;
}

.civik-reset-btn-wrap {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.civik-reset-pass-btn {
  margin-right: 0px;
}

.civik-auth-form-width {
  width: 410px;
}

// Verify Code input

.code-input {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.code-label {
  margin-bottom: 16px;
}
.code-inputs {
  display: flex;
  justify-content: start;
  align-items: center;
}
.code-inputs input {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: center;
  height: 65px;
  width: 65px;
  border-radius: 12px;
  margin: 10px 25px 0 0;
  margin-bottom: 50px;
  // border: 2px solid #4f5b66;
  background-color: #eef1f5;
  color: #5e6278;
  font-weight: 700;
  font-size: 21px;
}
.code-inputs input:focus {
  outline: none;
}

.code-inputs input:last-child {
  margin-right: 0px;
}

.civik-verify-resend-link {
  margin-top: 30px;
  text-align: center;
}

.civik-flex-row {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.civik-flex-row-wrap {
  display: flex;

  align-items: center;
}

.civik-acc-verify-banner {
  margin-bottom: 50px;
  border: 1px solid #c7c7c7;
  border-radius: 12px;
  padding: 15px;
}

.ml-10 {
  margin-left: 10px;
}

.feather-mail-icon {
  margin-right: 10px;
}
// .code-inputs input:nth-child(3n) {
//   margin-right: 24px;
// }

.civik-resend-email-link {
  background: none;
  border: none;
  color: #00a3ff;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

// council signup btn
.civik-council-signup-btn {
  // justify-content: space-around;
  margin-top: 30px;
  width: 400px;
  height: 70px;

  padding: 10px;
  background: rgba(54, 153, 255, 0.1);
  border: 1px dashed theme.$civik-primary-blue;
  box-sizing: border-box;
  border-radius: 9px;

  user-select: none;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.civik-council-icon {
  width: 40px;
  margin-right: 30px;
  margin-left: 10px;
}

.civik-flex-row {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.civik-flex-column {
  display: flex;
  flex-flow: column;
}

.civik-dark-blue {
  color: #181c32;
}

.civik-primary-blue {
  color: #009ef7;
}

.civik-primary-blue-active-tab {
  display: inline-block;
  position: relative;
}

.civik-primary-blue-link {
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.civik-primary-blue-active-tab:after {
  position: absolute;
  content: '';
  border-bottom: 2px solid #009ef7;
  width: 110%;
  transform: translateX(-50%);
  bottom: -10px;
  left: 50%;
}

.civik-dark-grey {
  color: #6c7289;
}

.civik-light-grey {
  color: #b5b5c3;
}

.civik-btn-wrap {
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.civik-txt-delete-btn {
  border: none;
  background: none;

  &:hover {
    opacity: 0.7;
    color: #f70000;
    cursor: pointer;
  }
}

.civik-txt-primary-btn {
  border: none;
  background: none;

  // &:hover {
  //   opacity: 0.7;
  //   color: #f70000;
  //   cursor: pointer;
  // }
}

// councilor signup

.civik-councilor-auth {
  margin-right: 200px;
  margin-left: 200px;
}

.civik-council-form-container {
  margin-left: 20%;
  margin-right: 20%;
}
.civik-council-inner-container {
  margin-top: 10px;
  margin-bottom: 50px;
}
.mr-40-responsive {
  margin-right: 40px;
}

.civik-concil-pic {
  margin-left: 115px;
  margin-right: 25px;
}

// datepicker
// .date-picker {
//   background-color: #f5f8fa;
//   padding: 12px;
//   border: none;
//   border-radius: 8px;
//   font-family: 'Poppins';
//   color: #a1a5b7;
//   font-weight: 500;
// }

.mr-10 {
  margin-right: 10px;
}

// profile pic circle
.civik-councilor-profile-pic {
  height: 140px;
  width: 140px;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.civik-council-staff-pic {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-right: 20px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.mobile-show {
  display: none;
}
// MODAL

.civik-modal {
  width: 700px;
  max-width: 700px;
  height: 100%;
  max-height: 800px;
}
.civik-modal-header {
  padding: 5px;
  border-bottom: 1px solid #eef2f5;
  padding-bottom: 10px;
}

.civik-modal-content {
  padding-left: 10px;
  padding-right: 10px;
}

.civik-justify-between {
  justify-content: space-between;
}

.civik-modal-title {
  color: #3f4254;
  font-weight: 500;
  margin-bottom: 0px;
}

.civik-modal-x-btn {
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.ml-5 {
  margin-left: 5px;
}

.save-btn {
  max-width: 180px;
  margin-left: 18px;
}
.skip-btn,
.prev-btn {
  max-width: 110px;
  margin-right: 25px;
}

.civik-edit-btn {
  max-width: 70px;
  font-size: 12px;
}

// .profile-pic-progress-bar {
//   margin-bottom: 5px;
// }

.civik-poli-btn-wrapper {
  justify-content: flex-end;
}

// VOTER PROFILE ------
.civik-wh-card {
  background-color: #fff;
  padding: 25px;
  border-radius: 8px;
}

.civik-step-number {
  background: #009ef7;
  height: 38px;
  width: 38px;
  border-radius: 6px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 21px;
    height: 21px;
  }

  h1 {
    text-align: center;
    margin-top: 5px;
    color: #fff;
  }
}

// --
// btn colors

.btn-light-grey {
  background-color: #eef2f5;
}

// politician

// voter profile

.civik-profile-about-container {
  width: 500px;
}

.civik-profile-edit-right-bar {
  width: 70%;
  margin-left: 30px;
  height: 100%;
  padding: 45px;
  overflow-y: scroll;
}

.civik-profile-edit-content-wrapper {
  height: 70vh;
}

// settings
.civik-justify-between {
  justify-content: space-between;
}

// main navbar

.civik-nav-active-pg-link {
  background-color: #eef2f5;
  padding: 10px;
  border-radius: 6px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.civik-nav-default-pg-link {
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.navbar__notifWrap {
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.civik-nav-icon-container {
  background-color: #eef2f5;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.navbar__elipsisBtn {
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.civik-nav-vote-btn {
  background-color: #009ef7;
  border-radius: 6px;
  border: none;
  padding: 8px 20px;
  color: #fff;
}

.ml-50 {
  margin-left: 50px;
}

.editProfile__tab {
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

//
// media queries
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .civik-profile-edit-cancel-btn {
    width: 100px;
  }
  .save-btn {
    max-width: none;
    margin-left: 0px;
  }

  .skip-btn {
    margin-right: 0px;
    min-width: 120px;
  }
  .prev-btn {
    margin-right: 10px;
    min-width: 120px;
  }

  .mobile-mb-20 {
    margin-bottom: 20px;
  }

  .civik-poli-btn-wrapper {
    justify-content: space-between;
  }

  .civik-login-email-form {
    max-width: 300px;
    // margin-left: px;
  }

  .civik-social-login-wrapper {
    margin-left: 0px;
    margin-right: 0px;
  }

  .civik-auth-form-width {
    width: 300px;
  }

  .civik-council-signup-btn {
    max-width: 300px;
  }
  .mobile-hide {
    display: none;
  }
  .civik-council-inner-container {
    max-width: 320px;
    margin-top: 30px;
  }

  .civik-council-staff-pic {
    width: 80px;
    height: 80px;
  }

  .civik-flex-row-wrap {
    flex-flow: row wrap;
  }
  .mr-40-responsive {
    margin-right: 0px;
  }

  .civik-concil-pic {
    margin-left: 0;
    margin-right: 0;
  }

  .mobile-show {
    display: block;
  }

  .civik-modal {
    width: 300px;
    max-width: 300px;
    height: 100%;
  }

  .civik-profile-edit-left-bar {
    display: none;
  }

  .civik-profile-edit-cancel-btn {
    min-width: 100%;
  }

  .civik-profile-edit-content-wrapper {
    height: 100%;
  }

  .civik-profile-edit-right-bar {
    width: 100%;
    margin-left: 0px;
    overflow-y: none;
  }

  .civik-profile-about-container {
    width: 100%;
  }

  .civik-profile-header-container {
    margin-top: -50px;
  }

  .civik-profile-header-tab {
    font-size: 12px;
  }

  .councilor-profile-edit-cancel-btn {
    width: 100px;
    margin-right: 10px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 1200px) {
  .civik-auth-page {
    margin-left: 0%;
  }
  .show-mobile {
    display: block;
  }
  .auth-footer-mobile {
    width: 100%;

    display: flex;
    justify-content: space-around;
    flex-flow: row;
    padding: 20px;
    border-top: 1px solid #c6c7cc;
  }

  .civik-council-form-container {
    margin-left: 0;
    margin-right: 0;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
  .hide-mobile {
    display: none;
  }
}

.civik-terms-page {
  padding: 0;
  align-items: center;
  height: 100vh;
  width: 100%;

  @media only screen and (min-width: 768px) {
    padding: 0 5%;
  }
  @media only screen and (min-width: 1200px) {
    padding: 5% 25%;
  }
}
.civik-terms-iframe {
  overflow: scroll;
  width: 100%;
  height: 100%;

  @media only screen and (min-width: 1200px) {
    width: 800px;
    overflow: scroll;
  }
}

.profile-pic-remove-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;

  color: #b5b5c3;
  margin-left: 16px;
  cursor: pointer;
}
.profile-pic-edit-icon {
  width: 50px;
  height: 50px;
  margin-left: -24px;
  cursor: pointer;
}
.profile-pic-edit-remove-container {
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.civik-dark-text {
  color: theme.$civik-dark-text;
}

.civik-light-btm-dashed-border {
  border-bottom: 1px dashed #e4e6ef;
}
.login__newHereTxt {
  color: #6c7289;
  font-weight: 500;
  font-size: 16px;
  margin-right: 5px;
}

.login__linkWrap {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.login__titleTxtWrap {
  margin-left: -25px;
}
