.TextField {
  height: 45px;
  padding: 20px;
  background: #f5f8fa;
  padding-left: 25px;
  border-radius: 8px;
  border: none;
  width: 100%;
  color: #3f4254;

  &:focus {
    outline: none;
  }
  font-size: 14px;
  font-weight: 500;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a1a5b7;
  }
}

.TextField__voter {
  height: 45px;
  // padding: 20px;
  background: #fff;
  padding-left: 15px;
  border-radius: 6px;
  border: 1px solid #000;
  width: 100%;
  color: #3f4254;

  &:focus {
    outline: none;
    border: 2px solid #000;
    box-shadow: 0px 0px 7px 3px rgba(54, 153, 255, 0.25);
  }
  font-size: 16px;
  font-weight: 400;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a1a5b7;
  }
}

.TextField__voterDisabled {
  opacity: 0.7;
  border: 1px solid rgb(186, 186, 186);
}

//

.TextField__voterErrWrap {
  // margin-top: 11px;
}
.TextField__voterErrTxt {
  color: #bc0404;
  font-size: 14px;
  font-weight: 500;
}

// ADMIN SEARCH BAR

.TextField__adminSearchBar {
  height: 35px;
  padding-left: 10px;
  display: flex;
  flex-flow: row;
}

.TextField__adminSearchBarImg {
  // width: 20.25px;
  // height: 20.25px;
  width: 34.8px;
}

.TextField__adminSearchBar__inner {
  border: none;
  outline: none;
  width: 100%;
  border-radius: 6px;
}
