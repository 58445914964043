.addKeypoint__contentWrap {
  padding: 34px 48px;
}

.addKeypoint__footerContainer {
}

.addKeypoint__footer {
  padding: 27px 23px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
}

.addKeypoint__footerCancelBtn {
  width: 95px;
  height: 47px;
  border-radius: 6px;
  border: none;
  color: #6c7289;
  font-size: 15px;
  padding: 0px;
  background-color: #eef2f5;
  margin-right: 20px;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.addKeypoint__footerBtnWrap {
  display: flex;
}
