.TermsError {
  background-color: #f1e0e5;
  height: 65px;
  border-radius: 6px;
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 20px;
  border: 1px dashed #ea001b;
}

.termsError__warningIcon {
  width: 32px;
  margin-right: 12px;
  margin-left: 23px;
}

.termsError__warningTxt {
  font-size: 16px;
  color: #3f4254;
  font-weight: 600;
}
