.Petitions {
  max-width: 600px;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;

  //   margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
}

// CREATE BANNER
.petitions__createBanner {
  //   margin-top: 50px;
  // width: 100%;
  //   height: 130px;

  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);
  border-radius: 12px;

  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  //   width: 100%;
  padding: 20px 24px 8px 24px;
}

.petitions__createBannerImg {
  align-self: flex-start;
  width: 68px;
  height: 68px;
}

.petitions__createBannerTxt {
  display: flex;
  flex-flow: column;
  margin-left: 20px;
}

.petitions__createBannerBtn {
  margin-left: 30px;
  height: 47px;
  width: 95px;
  padding: 12px 21px;
  background-color: #5f5cf1;
  border-radius: 6px;
  color: #ffffff;
  font-weight: 600;
  border: none;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

// PETITION POST
.petitions__post {
  width: 600px;
  margin-top: 20px;
  // width: 100%;
  //   height: 130px;

  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);
  border-radius: 12px;

  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  // overflow-y: scroll;

  //   display: flex;
  //   flex-flow: row;
  //   align-items: center;
}

.petitions__postHeader {
  padding: 10px 20px;
  border-bottom: 1px solid #ebedf3;
}

.petitions__postHeaderLabel {
  color: #6c7289;
  font-weight: 500;
  margin-right: 5px;
}

.petitions__postHeaderValue {
  color: #000;
  font-weight: 500;
}

.petitions_postBody {
  padding: 23px;
  display: flex;
  flex-flow: row;
  align-items: flex-start;
}

.petitions_postBodyTxtWrap {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 100%;
  margin-right: 20px;
}

.petitions_postBodyTxtTitle {
  line-height: 25px;
}

.petitions_postBodyTxtParagraph {
  line-height: 20px;
}

.petitions_postBodyTxtParagraph {
  font-weight: 500;
}

.petitions_postBodyImg {
  width: 170px;
  height: 170px;
  border-radius: 12px;
}

.petitions_postBodyOrganizedBy {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.petitions_postBodyOrganizedByImg {
  width: 42px;
  height: 42px;
  border-radius: 8px;
  margin-right: 0 !important;
}

.petitions_postBodyOrganizedByTxt {
  display: flex;
  flex-flow: column;
  margin-left: 10px;
}

.petitions_postBodyOrganizedByLabel {
  color: #6c7289;
}

.petitions_postBodyOrganizedByValue {
  color: #3f4254;
  font-weight: 600;
}

.petitions_postBodyReadMore {
  color: #f1416c;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.petitions_postFooter {
  // height: 92px !important;
  padding: 20px 25px;
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
  border-top: 1px solid #ebedf3;
}

.petitions_postFooterProgress {
  margin-left: 10px;
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-left: 20px;
}

.petitions_postFooterProgressTxt {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
}

.petitions_postFooterProgressBar {
  width: 100%;
  background-color: #ebedf3;
  max-height: 5px !important;
  border-radius: 10px;
  margin-top: 5px;
}

.petitions__postStatusBtn {
  margin-left: 30px;
  height: 47px;
  width: 250px;
  background-color: #f1416c;
  border-radius: 6px;
  color: #ffffff;
  font-weight: 600;
  border: none;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.petitions__postStatusBtnDraft {
  background-color: #50cd89;
}

.petitions_postFooterProgressLabel {
  color: #b5b5c3;
  font-weight: 500;
}

.petitions_postFooterSigned {
  color: #000;
  font-weight: 700;
}

.petitions_postFooterSignedMax {
  color: #f1416c;
  font-weight: 700;
}

.petitions_postFooterProgressPerc {
  font-weight: 600;
  color: #3f4254;
}

.petitions__postEditBtn {
  background-color: #50cd89;
}

.petitions_postFooterProgressBar100 {
  background-color: #50cd89;
}

.petitions__postSignBtn {
  background-color: #3699ff;
}

.petitions__postSignedBtn {
  background-color: #f1416c;
}

.petitions__statusLabel {
  background-color: #50cd89;
  border-radius: 6px;
  padding: 6px 11.5px;
}

.petitions__statusLabelDraft {
  background-color: #ff9e0c;
}

.petitions__statusLabelTxt {
  font-weight: 600;
  font-size: 12px;
  color: #fff;
}

.petitions__dateCreatedWrap,
.petitions__datePublishedWrap {
  display: flex;
  flex-flow: column;
  margin-top: 21px;
}

.petitions__dateCreatedLabel,
.petitions__datePublishedLabel {
  color: #6c7289;
  font-weight: 500;
  font-size: 12px;
}

.petitions__dateCreatedVal,
.petitions__datePublishedVal {
  color: #3f4254;
  font-weight: 600;
  font-size: 14px;
}

.petitions_bodyTxt {
  font-size: 14px;
}

.petitions__organizerImg {
  width: 42px;
  height: 42px;
  border-radius: 8px;
}

.myPetitions__tabs {
  margin-left: 51px;
}

.myPetitions__createdTab {
  font-weight: 600;

  font-size: 15px;

  /* Primary Blue */
  color: #3699ff;
}

.myPetitions__signedTab {
  font-weight: 500;

  font-size: 15px;

  /* Primary Blue */
  color: #6c7289;
  margin-left: 20px;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
  // CREATE BANNER
  .petitions__createBanner {
    margin-top: 95px;
  }

  .petitions__postContainer {
    &:last-child {
      margin-bottom: 95px;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  #createPetition__step1,
  #createPetition__step2,
  #createPetition__step3 {
    margin-bottom: 50px;
  }

  .petitions__postContainer {
    width: 100%;
  }
  .Petitions {
    max-width: 100%;
  }

  .petitions__createBannerTxt {
    margin-left: 0px;
  }

  // CREATE BANNER
  .petitions__createBanner {
    margin-top: 85px;
    margin-bottom: 7px;
    border-radius: 0px;
    // height: 118px;

    padding: 20px 14px 20px 21px;

    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    align-items: center;
  }

  .petitions__createBannerImg {
    width: 65px;
    height: 65px;
  }

  // PETITION POST
  .petitions__post {
    margin-top: 16px;
    border-radius: 0px;
    margin-top: 9px;
  }

  .petitions__postContainer {
    &:last-child {
      margin-bottom: 80px;
    }
  }

  .petitions__createBannerTitle {
    margin-left: 18px;
    font-size: 16px;
    line-height: 23px;
    margin-right: 20px;
  }

  .petitions__createBannerBtn {
    margin-left: 0px;
    height: 47px;

    padding: 12px 14px;

    font-size: 15px;
  }

  .petitions__createBannerReadMore {
    font-size: 12px;
    font-weight: 400;
    color: #5f5cf1;
    width: fit-content;
    margin-left: 85px;
    margin-top: -22px;
  }

  .petitions__postHeader {
    padding: 15px 15px 9px 15px;
  }

  .petitions__postHeaderLabel,
  .petitions__postHeaderValue {
    font-size: 14px;
  }

  .petitions_postBody {
    padding: 15px;
  }

  .petitions_postBodyImg {
    width: 121px;
    height: 121px;
    border-radius: 12px;
    margin-left: 12px;
    margin-top: 8px;
  }

  .petitions_postBodyTxtParagraph {
    font-size: 14px;
  }
  .petitions_postBodyTxtTitle {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .petitions_postBodyTxtWrap {
    margin-right: 0px;
  }

  .petitions_postBodyOrganizedByLabel {
    font-size: 12px;
  }
  .petitions_postBodyOrganizedByValue {
    font-size: 14px;
  }
  .petitions_postBodyOrganizedByImg {
    width: 46px;
    height: 46px;
  }

  .petitions_postFooterProgressLabel,
  .petitions_postFooterProgressPerc {
    font-size: 14px;
  }

  .petitions__postStatusBtn {
    // margin-left: 0px;
    height: 47px;
    margin-right: 15px;
    min-width: 170px;
    font-size: 15px;
  }

  .petitions__postStatusBtnWrap {
    width: 100%;
    display: flex;
    flex-flow: row;
    align-items: flex-end;
    align-self: flex-end;
  }

  .petitions_postFooter {
    padding: 12px 15px 22px 15px;
  }
  // PETITION POST
  .petitions__post {
    padding-bottom: 20px;
    width: 100%;
  }

  .Petitions {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media only screen and (max-width: 375px) {
  .petitions__createBannerReadMore {
    margin-left: 0px;
    margin-top: 0px;
  }
}
