.dupList__duplicateGroup--resolved {
  border: 2px solid #50cd89;
  border-radius: 6px;
}

.dupList__duplicateGroup--unresolved {
  border: 2px solid #a1a5b7;
  border-radius: 6px;
}

.dupList__duplicateGroup__header {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 18px;
  margin-left: 18px;
  margin-right: 34px;
  margin-bottom: 9px;
}

.dupList__duplicateGroup__header__idTxt {
  color: #3699ff;
  font-size: 14px;
  font-weight: 500;
  margin-right: 20px;
}

.dupList__duplicateGroup__header__statusTxtWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.dupList__duplicateGroup__header__statusTxt--resolved {
  color: #50cd89;
  font-size: 14px;
  font-weight: 500;
}

.dupList__duplicateGroup__header__statusTxt--unresolved {
  color: #e30000;
  font-size: 14px;
  font-weight: 500;
}

.dupList__duplicateGroup__header__leftTxtGroup {
  display: flex;
  flex-flow: row;
}

.dupList__duplicateGroup__header__rightTxtGroup {
}
.dupList__duplicateGroup__header__markBtn {
  color: #3699ff;
  font-size: 14px;
  font-weight: 500;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.dupList__duplicateGroup {
  margin-bottom: 29px;
}

.dupList__duplicateGroup__unresolvedIcon,
.dupList__duplicateGroup__resolvedIcon {
  margin-left: 8px;
}
