.createPetitionM__tabInactiveTxt {
  font-weight: 500;
  color: #3f4254;
  font-size: 12px;
}

.createPetitionM__tabInactive {
  margin-right: 10px;
  background-color: #f1faff;

  // background: #009ef7;
  height: 30px;
  width: 30px;
  border-radius: 9px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #f1faff;
}

.createPetitionM__toggleBar {
  margin-top: 5px;
  margin-left: 13px;
  padding-bottom: 0px;

  hr {
    color: #d9d9da;

    margin-top: 6px;
    padding: 0px;
    margin-left: -16px;
  }
}

.createPetitionM__title {
  font-size: 26px;
  margin-top: 31px;
  margin-bottom: 40px;
}

.createPetitionM__content {
  padding-left: 16px;
  padding-right: 16px;
}

.createPetitionM__headerTitle {
  color: #3f4254;
  font-weight: 500;
  margin-bottom: 0px;
  //   margin-left: 10px;
  font-size: 22px;
}

.createPetitionM__header {
  padding: 14px 16px;
  border-bottom: 1px solid #eef2f5;

  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.createPetitionM__footerContainer {
  margin-top: -30px;
  width: 100%;
}

.createPetitionM__footer {
  margin-top: 18px;
  padding: 0px 16px 44px 16px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
}

.createPetitionM__footerDivider {
  padding: 0px;
  margin: 0px;
  border-bottom: 1px solid #eef2f5;
  //   margin-left: -16px;
}

.createPetitionM__footerBtn {
  width: 78px;
  height: 47px;
  border-radius: 6px;
  border: none;
  color: #fff;
  font-size: 15px;
  padding: 0px;
  background-color: #3699ff;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .createPetitions__organizerContentWrap {
    width: 100%;
  }
}
