.adminEdit__verifyFooter {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}

.adminEdit__verifyFooter__labelWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.adminEdit__verifyFooter__label--verified {
  font-size: 14px;
  font-weight: 500;
  color: #50cd89;
}

.adminEdit__verifyFooter__checkIcon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

//

.adminEdit__verifyRequest__label {
  font-weight: 500;
  color: #3699ff;
  font-size: 14px;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}
