.AdminTopbar {
  height: 65px;
  width: 100%;
  padding-left: 45px;
  padding-right: 82px;
  background-color: #fff;

  position: absolute;
  top: 0;
  z-index: 1;

  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);
}

.adminTopbar__btnWrap {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.adminTopbar__logoutTxt {
  font-weight: 600;
  font-size: 16px;
  color: #3699ff;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}
