.feed__postReactions--homeFeed {
  margin-bottom: 18px;
  width: 100% !important;
  display: flex;
  align-items: center !important;
  justify-content: space-between !important;

  @media only screen and (max-width: 768px) {
    align-items: center;
  }
}

.feed__postReactions--topStory {
  justify-content: flex-start !important;
  margin-top: 15px;
}
