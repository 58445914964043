.civik-candidate-label,
.civik-politician-label {
  border: 1.5px solid #aa7762;
  padding: 0px 10px;
  color: #aa7762;
  margin-left: 25px;
  border-radius: 6px;
  font-weight: 500;
}

.civik-politician-label {
  color: #3699ff;
  border: 1.5px solid #3699ff;
}

.profile-header-wrap {
  margin-top: 30px;
}

.profileHeader__createPetitionBtn {
  width: 170px;
  height: 47px;
  background-color: #a1a5b7;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 600;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}
