// PROPERTY INFO MODAL
.voterAdminModals__propertyInfo {
  padding-top: 14px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 58px;
}

.voterAdminModals__propertyInfo__banner {
  background-color: #fff8dd;
  height: 84px;
  border-radius: 6px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.voterAdminModals__propertyInfo__bannerTxt {
  font-weight: 700;
  font-size: 16px;
  width: 276px;
  margin-left: 24px;
}

.voterAdminModals__propertyInfo__dislaimerTxt {
  font-size: 16px;
  font-weight: 400;
}

// table

// TABLE NEW
.voterAdminModals__propertyInfo__tableHeader {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  height: 43px;
  background-color: #f2f3f7;
  margin-top: 17px;
  padding-left: 28px;
  padding-right: 46px;
  border-radius: 6px;
}

.voterAdminModals__propertyInfo__tableBody__rowItem--1,
.voterAdminModals__propertyInfo__tableBody__rowItem--2,
.voterAdminModals__propertyInfo__tableBody__rowItem--3,
.voterAdminModals__propertyInfo__tableBody__rowItem--4 {
  font-size: 14px;
  font-weight: 500;
  color: #3f4254;
}

.voterAdminModals__propertyInfo__tableBody__row {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 28px;
  padding-right: 46px;
  height: 62px;

  border-bottom: 1px dashed #e4e6ef;
}

.voterAdminModals__propertyInfo__tableHeader__columnLabel--1,
.voterAdminModals__propertyInfo__tableHeader__columnLabel--2,
.voterAdminModals__propertyInfo__tableHeader__columnLabel--3,
.voterAdminModals__propertyInfo__tableHeader__columnLabel--4 {
  font-weight: 600;
  font-size: 14px;
  color: #3f4254;
}

.voterAdminModals__propertyInfo__tableHeader__columnLabel--1 {
  width: fit-content;
}

.voterAdminModals__propertyInfo__tableHeader__columnLabel--2 {
  width: 27%;
}

.voterAdminModals__propertyInfo__tableHeader__columnLabel--3 {
  width: 23%;
}

.voterAdminModals__propertyInfo__tableHeader__columnLabel--4 {
  width: 11%;
}

// ROW WIDTHS
.voterAdminModals__propertyInfo__tableBody__rowItem--1 {
  width: 5%;
}

.voterAdminModals__propertyInfo__tableBody__rowItem--2 {
  width: 25%;
}

.voterAdminModals__propertyInfo__tableBody__rowItem--3 {
  width: 20%;
}

.voterAdminModals__propertyInfo__tableBody__rowItem--4 {
  width: 11%;

  color: #50cd89;
  font-weight: 700;
  font-size: 12px;
  padding: 6px 11.5px;
  width: fit-content;
  background-color: #e8fff3;
  border-radius: 6px;
}

.voterAdminModals__propertyInfo__tableBody__rowItem--4--pending {
  color: #f1bc00;
  background-color: #fef2c7;
}

.PropertyInfoModal {
  width: 830px;
}

.propInfoModal__header {
  padding: 16px 20px 12px 30px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eef2f5;
}

.propInfoModal__title {
  font-size: 22px;
  font-weight: 500;
  color: #000;
}
