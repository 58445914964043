.publishPetiiton__body {
  padding: 25px;
}

.publishPetiiton__bodyTxt {
  font-size: 18px;
  font-weight: 400;
}

.publishPetition__footer {
  border-top: 1px solid #eef2f5;

  margin-top: 18px;
  padding: 27px 22px 30px 22px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
}

.publishPetition__footerBtn {
  width: 96px;
  height: 47px;
  border-radius: 6px;
  border: none;
  color: #fff;
  font-size: 15px;
  padding: 0px;
  background-color: #3699ff;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.publishPetition__previewBtn {
  width: 96px;
  height: 47px;
  border-radius: 6px;
  border: none;
  color: #6c7289;
  font-size: 15px;
  padding: 0px;
  background-color: #eef2f5;
  font-weight: 600;
  margin-right: 29px;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}
