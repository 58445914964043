.exportAddrList__bannerTxt__date {
  font-weight: 400;
  font-size: 16px;
  color: #50cd89;
}

.exportAddrList__banner {
  height: 81px;
  border: 1px dashed #50cd89;
  border-radius: 6px;
  background-color: #fff;
  margin-top: 28px;
  margin-bottom: 25px;

  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

  padding-top: 20px;
  padding-left: 15px;
  padding-bottom: 24px;
  padding-right: 20px;
}

.exportAddrList__bannerBtn {
  height: 35px;
  width: 122px;

  font-weight: 600;
  font-size: 14px;
  border: none;
  border-radius: 6px;
  color: #fff;
  background-color: #50cd89;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.exportAddrList__downloadBtn {
  height: 35px;
  width: 200px;

  font-weight: 600;
  font-size: 14px;
  border: none;
  border-radius: 6px;
  color: #fff;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.exportAddrList__downloadBtn--active {
  background-color: #50cd89;
}

.exportAddrList__downloadBtn--disabled {
  background-color: #eef2f5;

  &:hover {
    opacity: 1;
    cursor: auto;
  }
}
