.PetitionDetails__Outer {
  padding: 31px 180px 180px 180px;
  width: 100%;
  height: 100%;
  //   height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1200px) {
    padding: 30px;
    margin-bottom: 50px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0px;
  }
}

.PetitionDetails {
  max-width: 1140px;
  width: 100%;
  margin-top: 20px;
  padding: 30px;
  background-color: #fff;

  height: 100%;
  border-radius: 12px;

  @media only screen and (max-width: 1200px) {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 50px;
    margin-top: 0px;
    border-radius: 0px;
    padding: 15px;
  }
}

.petitionDetials__tabsWrap {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  width: 100%;
  margin-top: 60px;
  max-width: 1140px;
  margin-left: 60px;
}

.petitionDetials__nonActiveTab {
  font-weight: 500;
  color: #6c7289;
  font-size: 15px;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.petitionDetials__activeTab {
  font-weight: 600;
  color: #3699ff;
  font-size: 15px;
}

.petitionDetails_title {
  font-size: 30px;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.petitionDetails__coverImgWrap {
  // @media only screen and (max-width: 768px) {
  //   width: 100%;
  // }
}

.petitionDetails__coverImg {
  width: 100%;
  height: 340px;
  border-radius: 12px;
  margin-top: 24px;
}

.petitionDetails__coverMobileImg {
  width: 100%;
  height: 230.12px;
  margin-top: 105px;

  @media only screen and (max-width: 768px) {
    margin-top: 120px;
  }
}

.petitionDetails__socialsBar {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 21px;
}

.petitionDetails__socialBarBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;

  width: 99px;
  height: 34px;
  /* Dark Text */
  background: #3f4254;
  border-radius: 6px;

  font-weight: 400;
  font-size: 12px;

  color: #ffffff;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.petitionDetails__megaphoneIcon {
}

.petitionDetails__socialsBar__txt {
  font-size: 14px;
  margin-left: 7px;
}

.petitionDetails__socialsBar__signGoalTxt {
  font-weight: 700;
}

.petitionDetails__toLabel {
  font-size: 14px;
  color: #6c7289;
  font-weight: 500;
}
.petitionDetails__toValue {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}

.petitionDetails__toWrap {
  margin-top: 16.76px;
  display: flex;
  flex-flow: column;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #e1e1ee;
  border-bottom: 1px solid #e1e1ee;

  @media only screen and (max-width: 768px) {
    margin-top: 0px;
  }
}

.petitionDetails__descWrap {
  margin-top: 23px;
}

.petitionDetails__desc {
  font-size: 16px;
  font-weight: 400;
}

.petitionDetails__innerWrap {
  display: flex;
  flex-flow: row;
  width: 100%;

  @media only screen and (max-width: 1200px) {
    flex-flow: column;
  }

  @media only screen and (max-width: 768px) {
    flex-flow: row wrap;
  }
}

.petitionDetails__signBar {
  background: #f2f3f7;
  padding: 25px 25px 29px 25px;
  margin-left: 89px;
  max-width: 291px;
  width: 100%;
  border-radius: 12px;
  max-height: 500px;

  @media only screen and (max-width: 1200px) {
    margin-bottom: 0px;
    margin-left: 0px;
    margin-top: 15px;
    max-width: 100%;
    width: 100% !important;
  }
}

.petitionDetails__signBarStats {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.petitionDetails__signBarStatsTxt {
  font-size: 20px;
  font-weight: 500;
  color: #6c7289;
}

.petitionDetails__signBar__percent {
  font-weight: 600;
  color: #000;
  font-size: 18px;
  align-self: flex-end;
}
.petitionDetails__signBar__percentBar {
  width: 100%;
  background-color: #ebedf3;
  height: 15px;
  border-radius: 10px;
  margin-top: 5px;
}

.petitionItem__signBar {
  height: 5px !important;
}

.petitionDetails__signBar__numOfSigns {
  font-weight: 700;
  color: #000;
  font-size: 30px;
}

.petitionDetails__signBar__numOfSignsGoal {
  font-weight: 700;
  color: #f1416c;
  font-size: 20px;
}

.petitionDetails__signBar__editBtn,
.petitionDetails__signBar__publishBtn {
  width: 100%;
  height: 50px;
  border-radius: 6px;
  border: 2px solid #3699ff;
  font-size: 15px;
  color: #3699ff;
  background-color: #fff;
  font-weight: 600;
  margin-top: 24px;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.petitionDetails__signBar__publishBtn {
  background-color: #3699ff;
  border: none;
  color: #fff;
  margin-top: 24px;
}

.petitionDetails__signBar__signedBtn {
  background-color: #f1416c;
  border: none;
  color: #fff;
  margin-top: 24px;
}

.petitionDetails__signBar__date {
  display: flex;
  flex-flow: column;
  margin-top: 24px;
}

.petitionDetails__signBar__sch,
.petitionDetails__signBar__statusWrap {
  display: flex;
  flex-flow: column;
  margin-top: 20px;
}

.petitionDetails__signBar__dateLabel,
.petitionDetails__signBar__statusLabel {
  font-size: 14px;
  font-weight: 500;
  color: #6c7289;
}

.petitionDetails__signBar__status {
  background-color: #e1efec;
  border-radius: 6px;
  margin-top: 8px;
  height: 26px;
  width: 77px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.petitionDetails__signBar__statusTxt {
  color: #50cd89;
  font-weight: 700;
  font-size: 12px;
}
.petitionDetails__signBar__dateValue {
  font-size: 16px;
  font-weight: 600;
  color: #3f4254;
}

.petitionDetails__organizerContentWrap {
  margin-top: 20px;
  display: flex;
  flex-flow: row;
  align-items: center;
}

.petitionDetails__organizerImg {
  width: 46px;
  height: 46px;
  border-radius: 6px;
}

.petitionDetails__organizerTxtLabel {
  color: #6c7289;
  font-size: 14px;
  font-weight: 500;
}

.petitionDetails__organizerTxtValue {
  color: #000;
  font-size: 14px;
  font-weight: 700;
}

.petitionDetails__signBar__dateMobile {
  display: flex !important;
  flex-flow: row !important;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.petitionDetails__floatingSocialBar {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  height: 112px;
  width: 100%;
  z-index: 999999999999;
}

.petitionDetails__floatingSocialBarContent {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: 14px;
  margin-right: 14px;
}

.petitionDetails__copyLinkBtn {
  border-radius: 6px;

  font-weight: 600;
  font-size: 14px;

  border: 2px solid #6c7289;
  font-size: 14px;
  color: #6c7289;
  background-color: #fff;
  font-weight: 600;

  margin-right: 18px;

  width: 140px;
  height: 47px;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.petitionDetails__header {
  position: fixed;
  top: 0px;
  background-color: #fff;
  width: 100%;
  z-index: 9999999999;
}

.petitionDetailsM__targetBar {
  padding: 13px 16px;
}

.petitionDetails__successTxt {
  color: #f1416c;
  font-weight: 600;
  font-size: 15px;
}

.petitionDetails__percentWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.petitionDetails__signBar__percentBar {
  width: 100%;
  background-color: #ebedf3;
  height: 15px;
  border-radius: 10px;
  margin-top: 5px;
}

.petitionDetails__progressBarInProgress {
  .progress-bar {
    background-color: #f1416c !important;
  }
}

.petitionDetails__progressBarComplete {
  .progress-bar {
    background-color: #50cd89 !important;
  }
}

.petitions__createBannerImgSmall {
  width: 50px;
  height: 50px;
}

.petitions__createBannerSubTxt {
  margin-bottom: 0;
  font-size: 16px;
}

.petitionsDetails__createPetBtn {
  margin-left: 30px;
  height: 47px;
  width: 156px;
  padding: 12px 21px;
  background-color: #5f5cf1;
  border-radius: 6px;
  color: #ffffff;
  font-weight: 600;
  border: none;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.petitionsDetails__createBanner {
  margin-top: 30px;
  padding: 20px 24px 20px 24px;

  //   margin-top: 50px;
  // width: 100%;
  //   height: 130px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);
  border-radius: 12px;

  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .petitionDetails__signBar__dateLabel {
    margin-bottom: 8px;
  }
  .petitionDetails__signBar__sch {
    margin-right: 22px;
  }

  .petitions__createBanner {
    // width: 110%;
    // margin-left: -6%;
  }

  .petitionsDetails__createBanner {
    margin-top: 0px;
  }

  // .petitionDetails__body {
  //   width: 100%;
  // }

  .petitionDetials__tabsWrap {
    margin-top: 0;
    margin-left: 15px;
  }
}

.petitionDetails__body {
  width: 100%;
  max-width: 700px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    max-width: none;
  }
}

.PetitionPreview {
  max-width: 1140px;
  width: 100%;
  margin-top: 20px;
  padding: 30px;
  background-color: #fff;

  height: 100%;
  border-radius: 12px;

  @media only screen and (max-width: 1200px) {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 50px;
    margin-top: 70px;
    border-radius: 0px;
    padding: 15px;
  }
}

.petitionDetails__copyLinkIcon {
  margin-right: 6px;
}

.petitionDetails__tabWrap {
  margin-right: 30px;
}

.petitionDetails__tabIndicatorActive {
  height: 2px;
  width: 137px;
  background-color: #3699ff;
  border-radius: 3px;
  margin-top: 12px;
  margin-left: -25px;
}

.petitionDetails__tabIndicatorActiveM {
  height: 3px;
  width: 113px;
  background-color: #3699ff;
  border-radius: 3px;
  margin-top: 12px;
  margin-left: -20px;
}

.petitionDetails__tabWrapM {
  margin-top: 10px;
}

.petitionDetails__tabTxtM {
  font-size: 12px;
}

.petitionDetails__headerWrapM {
  padding: 14px 16px 0px 16px;
  border-bottom: 1px solid #eef2f5;

  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.petitionDetails__activeSupportTabM {
  margin-left: 34px;
}

.petitionDetails__activeSupportTab {
  margin-left: 10px;
}

.petitionDetails__activeTab {
  margin-left: 30px;
}

.petitionDetails__activeDescTabM {
  margin-right: 10px;
}
