.CreatePetition {
  height: 70vh;
  display: flex;
  flex-flow: row;
}

.createPetition__leftSideBar {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: space-between;

  width: 30%;

  padding-left: 50px;
  padding-right: 50px;
  // height: 100%;

  background-color: #fff;
  padding: 55px 58px;
  border-radius: 8px;
}

.createPetition__leftBar__tab {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 31px;
}

.createPetition__leftBar__tabNum {
  margin-right: 25px;
  background-color: #f1faff;

  background: #009ef7;
  height: 40px;
  width: 40px;
  border-radius: 9px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.createPetition__leftBar__tabNumVal {
  text-align: center;

  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.createPetition__leftBar__tabLabel {
  display: flex;
  flex-flow: column;
}

.createPetition__leftBar__tabLabelVal {
  font-size: 16px;
  font-weight: 500;
  color: #3f4254;
}
.createPetition__leftBar__tabLabelSm {
  font-size: 12px;
  font-weight: 500;
  color: #a1a5b7;
}

.createPetition__leftBar__tabInactive {
  background-color: #f1faff;
}

.createPetition__leftBar__tabNumValInactive {
  color: #009ef7;
}

.createPetition__leftSideBar__footer {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.createPetition__leftSideBar__footerImg {
  width: 36px;
}

.createPetition__leftSideBar__footerTxt {
  text-align: center;
  margin-top: 15px;
  font-size: 11px;
  font-weight: 500;
  color: #a1a5b7;
}

.createPetition__leftSideBar__footerLink {
  color: #009ef7;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
}

.createPetition__topicDropdown {
  max-width: 688px;
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
}

// organizer

.createPetitions__organizerContainer {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 50px;
}

.createPetitions__organizerWrap,
.createPetitions__organizerAddWrap {
  display: flex;
  flex-flow: column;
}

.createPetitions__organizerContentWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
  height: 88px;
  width: 310px;
  border: 1px solid #d9dbe2;
  border-radius: 6px;
  margin-top: 4px;
}

.createPetitions__organizerTxtWrap,
.createPetitions__organizerAddTxtWrap {
  display: flex;
  flex-flow: column;

  margin-left: 14px;
}

.createPetitions__organizerImg {
  width: 46px !important;
  height: 46px !important;
  border-radius: 6px;
  margin-left: 21px;
}

.createPetitions__organizerTxtLabel {
  color: #b5b5c3;
  font-size: 13px;
  font-weight: 500;
}

.createPetitions__organizerTxtVal,
.createPetitions__organizerAddTxtVal {
  color: #3f4254;
  font-size: 14px;
  font-weight: 500;
}

.createPetitions__organizerAddTxtWrap {
  margin-left: 0px;
}

.createPetitions__organizerAddTxtVal {
  padding: 20px 40px 20px 20px;
}

// step 2 - petition details

.createPetition__details__header {
  display: flex;
  flex-flow: column;
}

.createPetition__details__headerTitle {
  font-size: 26px;
  margin-bottom: 9px;
  color: #181c32;
}
.createPetition__details__headerSubtxt {
  font-size: 14px;
  margin-bottom: 20px;
  color: #181c32;
  font-weight: 500;
}

.createPetition__point {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}

.createPetition__pointTxt,
.createPetition__docTxt {
  font-size: 16px;
  font-weight: 500;
  margin-left: 22px;
}

.createPetition__pointOuterWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.createPetition__pointIconContainer {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.createPetition__pointEditIcon {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  margin-right: 15px;
}

.createPetition__pointDeleteIcon {
  width: 32px;
  height: 32px;
  border-radius: 6px;
}

.createPetition__pointEditIcon {
  // max-width: 32px;
  // max-height: 32px;

  padding: 4px;

  &:hover {
    background-color: #f1faff;
    cursor: pointer;

    path {
      fill: #009ef7;
      opacity: 1;
    }
  }
}

.createPetition__pointDeleteIcon {
  padding: 4px;

  &:hover {
    background-color: #fff1f1;
    cursor: pointer;

    path {
      fill: #f70000;
      opacity: 1;
    }
  }
}

.createPetition__btnContainer {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 36.5px;
}

.createPetition__btnWrap {
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.createPetition__btnTxt {
  font-size: 15px;
  color: #009ef7;
  margin-right: 7px;
  font-weight: 500;
}

.profileEdit__rightEditForm {
  // height: 100%;
}

.createPetition__docUploadWrap {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  // height: 311px;
  border: 1.5px dashed #00a3ff;

  background-color: #f1faff;
  border-radius: 12px;

  height: 163px;
  margin-top: 18px;

  @media only screen and (max-width: 768px) {
    height: 250px;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.createPetition__docDetailsRow {
  margin-top: 18px;
}

.createPetition__docTxt {
  margin-left: 0px;
}

.createPetition__petitionDescInput {
  height: 235px;
}

.civik-profile-edit-right-bar {
}

.createPetition__loadingScreen {
  height: 500px !important;

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 16px;
  }
}

.createPetition__prevImgWrap {
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.createPetition__supportTargetWrap {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 29px;
  margin-bottom: 38px;
}

.createPetition__supportTargetBtnWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  width: 232px;
  height: 42px;
  background-color: #f5f8fa;
  padding: 10px;
  border-radius: 6px;
}

.createPetition__supportTargetBtn {
  // width: 21.67px;
  // height: 21.67px;
  // border-radius: 4px;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.createPetition__supportTargetVal {
  font-size: 22px;
  font-weight: 600;
  color: #5e6278;
}

.createPetition__supportTargetLabel {
  font-size: 22px;
  font-weight: 500;
  color: #3f4254;
}

.creatPetition_prevImg {
  // width: 250px;
  height: 400px;
  width: 100%;
  border-radius: 12px;

  @media only screen and (max-width: 768px) {
    height: 250px;
    width: 100%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
  .createPetition__topicDropdown {
    max-width: fit-content;
  }

  .createPetition__leftSideBar {
    display: none;
  }

  .civik-profile-edit-right-bar {
    width: 100% !important;
    margin-left: 0px !important;
  }

  .CreatePetition {
    height: auto;
    // position: fixed;

    // z-index: 9999999999999999;
  }

  .createPetitions__organizerContainer {
    flex-flow: column;
  }

  .createPetitions__organizerAddWrap {
    margin-top: 14px;
  }
}

.createPetition__termsErr {
  margin-top: 10px;
  font-size: 14px;
  color: #f70000;
  font-weight: 500;
}
