.VoterAdminLogin {
  background-color: #e1edf9;
  height: 100vh;
  width: 100%;
}

.voterAdminLogin__form {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 94px;
}

.voterAdminLogin__inputsWrap {
  display: flex;
  flex-flow: column;
  max-width: 440px;
  width: 100%;
}

.voterAdminLogin__usernameWrap {
  margin-bottom: 43px;
}

.voterAdminLogin__passwordWrap {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.voterAdminLogin__passwordInputWrap {
  width: 100%;
}

.voterAdminLogin__loginBtn {
  margin-top: 44px;
  height: 47px;
  width: 100%;
  border-radius: 6px;

  background-color: #3699ff;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  border: none;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.voterAdminLogin__forgotPassTxt {
  color: #3699ff;
  font-weight: 600;
  font-size: 14px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.voterAdminLogin__passwordLabelsWrap {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
