.news__headlines__card {
  background-color: #fff;
  display: flex;
  flex-flow: column;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);
  padding: 20px 20px 16px 20px;
  border-radius: 12px;
  margin-top: 24px;
  width: 100%;

  @media only screen and (max-width: 768px) {
    height: 270px;
  }
}
.news__headlines__content {
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-flow: column;
  }
}

.news__headlines__content__txtOuterCont {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-bottom: 22px;
  width: 100%;
}

.news__headlines__content__img {
  width: 150px;
  height: 114px;
  border-radius: 12px;

  @media only screen and (max-width: 768px) {
    width: 80px;
    height: 80px;
    margin-left: 18px;
  }
}

.newsHeadlines__headline {
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-flow: column;
  }
}

.news__headlines__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.news__section__fullPageView__header {
  display: flex;
  // justify-content: space-around;
  align-items: center;
  width: 100%;
  background-color: yellow;
}
