.confirmCode__formHeaderTxt {
  font-weight: 500;
  font-size: 18px;
  //   margin-top: 21px;
}

.verifyMethod__formHeaderTxtWrap {
  display: flex;
  flex-flow: column;
  margin-top: 21px;
}

.confirmCode__formFooterTxt {
  font-weight: 500;
  font-size: 18px;
}

.confirmCode__formFooter {
  margin-top: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmCode__form {
  position: absolute;
  margin-top: 89px;

  max-width: 830px;
  width: 100%;
  //   height: 465px;

  border-radius: 12px;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);

  padding: 46px 190px 90px 190px;
}

.confirmCode__btn {
  width: 470px;
  height: 50px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  background-color: #3699ff;
  border-radius: 6px;
  border: none;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.confirmCode__inputsWrap {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin-bottom: 50px;
}

// CONFIRM CODE TEXTFIELD

.TextField__confirmCode__container {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.TextField__confirmCode {
  width: 60px;
  height: 60px;
  padding-top: 5px;
  border-radius: 12px;
  border: 1px solid black;
  margin-right: 12px;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}

.TextField__confirmCode__inactive {
  background-color: #fff;
}

.TextField__confirmCode__selected {
}

.confirmCode__errorWrap {
  margin-top: 25px !important;
}

.confirmCode__resendLink {
  &:hover {
    opacity: 0.8;
    cursor: pointer;
    text-decoration: underline;
  }
}
